import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'app-subtoolbar',
    templateUrl: './subtoolbar.component.html',
    styleUrls: ['./subtoolbar.component.scss'],
})

export class SubToolBarComponent {
    gridVertical: any;
    sizeUpper: string;
    toolBarStyle: any;
    color_corp: any;


    constructor(public rest: RestService, private router: Router, private translateService: TranslateService) {
        this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
        this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];

        this.color_corp = this.rest.colorCorp ? this.rest.colorCorp : '#025c81';

        //Comprobar que vengan estilos del JSON para que no pete APP a su creacion desde CMS -- Temporal hasta arreglo desde CMS
        this.toolBarStyle = this.rest.response.items[this.sizeUpper + 'Vertical'].subtoolbar;
        if (this.toolBarStyle.show && this.toolBarStyle.style === undefined){
            const toolBarStyleJson = {
              "fontSize": 0.8,
              "fontColor": "#FFFFFF",
              "fontFamily": "Helvetica",
              "backgroundColor": "#F5F5F5",
              "backgroundColorElement": "#3a99d7",
              "shawdow": "0px 0px 0px rgba(0,0,0,1) transparent",
              "fontBold": "normal",
              "fontItalic": "normal",
              "fontUnderline": "none"
            }
            this.toolBarStyle.style = toolBarStyleJson;      
        }
      }

    clickSubToolbar(subtoolbarItem, subtoolbarType, itemModuleDatas){

        if(itemModuleDatas.type === 'pointsInterest'){
          this.router.navigate(["/list-new"], {queryParams: {pointinterestid: itemModuleDatas.id, reload: true}});
        }

        if(itemModuleDatas.type === 'pointsInterestID'){
          this.router.navigate(["/product-new"], {queryParams: {pointinterestid_id: itemModuleDatas.id, reload: true}});
        }
    
        if(itemModuleDatas.type === 'parentTypePointsInterest'){
          this.router.navigate(["/list-new"], {queryParams: {parentpointinterestid: itemModuleDatas.id, reload: true}});
        }
    
        if(itemModuleDatas.type === 'callejero'){
          this.router.navigate(["/map-new"], {queryParams: {streetmap: 'ok'}});
        }

        //Añadido para Galeria
        if(itemModuleDatas.type === 'gallery'){
          this.router.navigate(["/list-new"], {queryParams: {gallery: itemModuleDatas.id}});
        }
    
        if(itemModuleDatas.type === 'pdf'){
          if(itemModuleDatas.menu == '1'){
            this.router.navigate(["/list-new"], {queryParams: {pdfId: itemModuleDatas.id, reload: true}});  
          }
          else{
            this.router.navigate(["/pdf"], {queryParams: {pdfId: itemModuleDatas.id, reload: true}});
          }
        }
    
        if(itemModuleDatas.type === 'link'){
          try {  
            const links = JSON.parse(itemModuleDatas.data);
            let link = links[this.rest.codeUsedLang];
            if (link == ''){
              link = links['es'];
            }
            if(link == 'camaras'){
              this.rest.camara = true;
              this.router.navigate(["/aire"]);
            }
            else{
            this.router.navigate(["/iframe"], {queryParams: {link: link, id: itemModuleDatas.id}});
          }
          }catch (e) {
            if(itemModuleDatas.data == 'camaras'){
              this.rest.camara = true;
              this.router.navigate(["/aire"]);
            }
            else{
              this.router.navigate(["/iframe"], {queryParams: {link: itemModuleDatas.data, id: itemModuleDatas.id}});
            }
          }
        }
    
        if(itemModuleDatas.type === 'selfie'){
          if(this.rest.selfie == false){ 
            this.router.navigate(["/iframe"], {queryParams: {id: 'mt'}});
          }
          else{
            this.router.navigate(["/selfie"], {queryParams: {id: 'mock'}});
          }
        }

        if(itemModuleDatas.type === 'multiLevel'){
          this.router.navigate(["/multi-level"], {queryParams: {multiLevelid: itemModuleDatas.id, reload: true}});
        }
      }
}