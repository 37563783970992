import { Component, OnInit } from '@angular/core';
import * as create360Viewer from '360-image-viewer';
import * as canvasFit from 'canvas-fit';

@Component({
  selector: 'app-photo360',
  templateUrl: './photo360.page.html',
  styleUrls: ['./photo360.page.scss'],
})
export class Photo360Page implements OnInit {
  foto;

  constructor() { }

  ngOnInit() {
    const image = new Image();
image.src = '/assets/360.jpg';
 
image.onload = () => {
  // when the image is loaded, setup the viewer
  const viewer = create360Viewer({
    image: image
  });
  window.document.getElementById('canvas').appendChild(viewer.canvas);
  const fit = canvasFit(viewer.canvas, window, window.devicePixelRatio);
  window.addEventListener('resize', fit, false);
  fit();
  // setup fullscreen canvas sizing
  // start the render loop
  viewer.start();
};
  }

}
