import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-banana',
  templateUrl: './banana.page.html',
  styleUrls: ['./banana.page.scss'],
})
export class BananaPage implements OnInit {

  public form: FormGroup;
  public mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  public politica = false


  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private alertCtrl: AlertController) { }

  ngOnInit(): void {

  }
}

