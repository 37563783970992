import { Component, Input } from '@angular/core';
import { ModalController, Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import { TranslateService } from '@ngx-translate/core';
import QRCode from 'qrcode';

@Component({
    selector: 'app-weatherqr',
    templateUrl: './weatherqr.component.html',
    styleUrls: ['./weatherqr.component.scss'],
})

export class WeatherQRComponent {
    mathRound = Math.round;
    gridVertical: any;
    sizeUpper: string;
    bannerBackgroundColor: any;
    daysWeekShortening: any;
    daysWeek: any;
    qr: any;
    color_corp: any;
    GUI: any;

    constructor(private translateService: TranslateService, public rest: RestService, private router: Router, public alertController: AlertController) {
        this.sizeUpper = this.rest.size.charAt(0).toUpperCase() + this.rest.size.slice(1);
        this.gridVertical = this.rest.response.items[this.sizeUpper + 'Vertical'];

        if (this.gridVertical.banner.show == 'true'){
            this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColor;
        }

        this.daysWeekShortening = ['Dom','Lun','Mar','Mie','Jue','Vie','Sab'];
        this.daysWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

        //Color Corporativo
        this.color_corp = this.rest.colorCorp ? this.rest.colorCorp : '#eee';
    }

    ngOnInit() {
        this.GUI = {
            bannerMonitor: window.document.getElementById('bannerMonitor')
        }
    }

    weatherNextSevenDays(weatherId: string){
        let weatherDaily = this.rest.response.datas['weather' + weatherId].daily;
        return [weatherDaily[0], weatherDaily[1], weatherDaily[2], weatherDaily[3], weatherDaily[4], weatherDaily[5], weatherDaily[6]];
    }

    weatherThreeHour(weatherId: string){
        const weatherHourly = this.rest.response.datas['weather' + weatherId].hourly;
        return [weatherHourly[0], weatherHourly[3], weatherHourly[6], weatherHourly[9], weatherHourly[12], weatherHourly[15], weatherHourly[18], weatherHourly[21]];
    }

    timeToDayWeekShortening(time: number){
        const dateTime = new Date(time * 1000);
        //Traduccion dias de la semana en banner tiempo
        const dia = this.getDayShort();
        return dia[dateTime.getDay()];
    }

    timeToDayWeek(time: number){
        const dateTime = new Date(time * 1000);
        //Traduccion dias de la semana en banner tiempo
        const dia = this.getDayFull();
        return dia[dateTime.getDay()];
    }

    timeToHourly(time){
        const dateTime = new Date(time * 1000);
        return `${this.formatDateWithCero(dateTime.getHours())}:${this.formatDateWithCero(dateTime.getMinutes())}`;
    }

    dateNumber(time){
        const dateTime = new Date(time * 1000);
        return dateTime.getDate();
    }

    formatDateWithCero(number){
        return number < 10 ? `0${number}` : number;
    }

    clickBannerMonitor(event){
        if(this.GUI.bannerMonitor.classList.contains('banner-monitor-maximize')){
            this.GUI.bannerMonitor.classList.replace('banner-monitor-maximize', 'banner-monitor-minimize');
            this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColor;
            /* this.GUI.bannerMonitor.classList.add('banner-monitor');
            this.GUI.bannerMonitor.classList.remove('banner-monitor-maximize'); */
        }else{
            this.GUI.bannerMonitor.classList.replace('banner-monitor-minimize', 'banner-monitor-maximize');
            this.bannerBackgroundColor = this.gridVertical.banner.style.backgroundColorExpand;
            //Cambio de color coroportavo en baner tiempo LOECHES
            if (this.rest.mac === "loeches"){
                this.bannerBackgroundColor = this.color_corp;
            }
            /* this.GUI.bannerMonitor.classList.add('banner-monitor-maximize');
            this.GUI.bannerMonitor.classList.remove('banner-monitor'); */
        }
    }

    getDayShort(){
        const dayShort = {
            es: ['Dom','Lun','Mar','Mie','Jue','Vie','Sab'],
            en: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
            fr: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
            de: ['So','Mo','Di','Mi','Do','Fr','Sa']
        };
        return dayShort[this.rest.codeUsedLang] ? dayShort[this.rest.codeUsedLang] : dayShort['es'];
    }

    getDayFull(){
        const dayFull = {
            es: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            en: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
            fr: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            de: ['So','Mo','Di','Mi','Do','Fr','Sa']
        };
        return dayFull[this.rest.codeUsedLang] ? dayFull[this.rest.codeUsedLang] : dayFull['es'];
    }

    //Presentar QR de abajo
    async presentAlert() {
        this.makeQRCode();
        const textQR = "Llévatelo a tu móvil";
        const QRTranslated = (this.translateService.translations[this.translateService.currentLang])[textQR];
        const alert = await this.alertController.create({
            cssClass: 'custom-alert-danger',
            header: QRTranslated,
            message: `<img src="${this.qr}" style="border-radius: 2px">`,
            //buttons: ['OK']
        });

        await alert.present();
        setTimeout(() => {
            alert.dismiss();
        }, 30000);

        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    //QR para poder acceder a la web
    makeQRCode() {
        const qrcode = QRCode;
        const self = this;
        qrcode.toDataURL(`https://app.iurban.es/?mac=${this.rest.mac}&newcms=true&size=monitor`, { errorCorrectionLevel: 'H' }, function (err, url) {
            self.qr = url;
        });
    }

}